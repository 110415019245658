import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import { Card, CardBody, CardHeader, Row } from 'reactstrap';
import avatar2 from "../../assets/images/users/avatar-2.jpg"
import axios from 'axios';
import moment from "moment"
import Breadcrumb from "../../components/Common/Breadcrumb"
import Pusher from 'pusher-js';
export default function ComunityChat(props)
{
    const now = moment();
    const  student_course  = props.location.state ? props.location.state.student_course : props.history.push('comunity')
    const  course_id  = props.location.state ? props.location.state.course_id : props.history.push('comunity')
    const  batch_id  = props.location.state ? props.location.state.batch_id : props.history.push('comunity')
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");

    const [course, setCourse] = useState("");

    const messageContainerRef = useRef(null);
    const messagesEndRef = useRef(null);
    let event = course_id + "-" + batch_id;
    useEffect(() => {
        // Enable pusher logging - don't include this in production
    //     Pusher.logToConsole = true;
    
        const pusher = new Pusher('e4d6748d6ab573a39b5f', {
          cluster: 'ap2',
        });
      console.log(event)
        const channel = pusher.subscribe('my-channel');
    
        const handlePusherEvent = (data) => {
        //  console.log()
       let message = JSON.parse(data.message)
       if(message.sender !== student_course)
       {
        setMessages((prev) => [...prev, JSON.parse(data.message)]);
       }
          
        };
    
        // Bind the event to the callback function
        channel.bind(event, handlePusherEvent);
    
        // Cleanup function to unsubscribe from the channel when the component unmounts
        return () => {
          channel.unbind('my-channel', handlePusherEvent);
          pusher.unsubscribe(event);
        };


   
      },[]);
      useEffect(() => {
         
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${global.config.Token}`;
  
      const getChat = async () => {
        
        const chat = await axios.post(global.config.URL.api.lms + 'get-messages',{'course' : student_course});
    //    console.log(chat.data)
        setMessages(chat.data.chat)
        setCourse(chat.data.course)
        
      }
      getChat();
    },[]);
    axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${global.config.Token}`;
    
      const postMessage = async (messageData) => {
        const data = {"message":messageData,"course" :student_course}
        const courses = await axios.post(global.config.URL.api.lms + 'post-message',data);
      
      }
  const sendMessage = (e) => {
    e.preventDefault();
    const messageData = {
      sender:   student_course,
      receiver: "PFTP",
      message,
    };
   
      setMessage("");
      setMessages((prev) => [...prev, messageData]);
      postMessage(messageData)
   
  };

  useEffect(() => {
    // Scroll to the bottom when messages change
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  }, [messages]);
    return(
      <React.Fragment>
         <div className="page-content">
         <Breadcrumb title="Dashboard" breadcrumbItem={`PFTP | ${course}  Comunity`} />
         <div class="page">
        <div class="marvel-device nexus5">
          <div class="top-bar"></div>
          <div class="sleep"></div>
          <div class="volume"></div>
          <div class="camera"></div>
          <div class="screen">
            <div class="screen-container">
              
              <div class="chat">
                <div class="chat-container">
                  <div class="user-bar">
                    <div class="back">
                      <i class="zmdi zmdi-arrow-left"></i>
                    </div>
                    <div class="avatar">
                      <img src={avatar2} alt="Avatar"/>
                    </div>
                    <div class="name">
                      <span>PFTP | {course}  Comunity <small>(BETA)</small></span>
                      <span class="status">online</span>
                    </div>
                    
                  </div>
                  <div class="conversation" >
                    <div class="conversation-container" ref={messageContainerRef}>
                      
                      {messages.map((message, index) => (
                      <div class={ message.sender === student_course ? "message sent" : "message received"}>
                       {message.message}
                        <span class="metadata"><span class="time">{now.diff(message.timestamp, 'hours') <= 2 ? moment(message.timestamp).fromNow() : moment(message.timestamp).format('lll') }</span></span>
                      </div>
                      ))}
                    </div>
                    <div ref={messagesEndRef}></div>
                    <form class="conversation-compose" onSubmit={sendMessage} >
                      <div class="emoji">
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="smiley" x="3147" y="3209"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.153 11.603c.795 0 1.44-.88 1.44-1.962s-.645-1.96-1.44-1.96c-.795 0-1.44.88-1.44 1.96s.645 1.965 1.44 1.965zM5.95 12.965c-.027-.307-.132 5.218 6.062 5.55 6.066-.25 6.066-5.55 6.066-5.55-6.078 1.416-12.13 0-12.13 0zm11.362 1.108s-.67 1.96-5.05 1.96c-3.506 0-5.39-1.165-5.608-1.96 0 0 5.912 1.055 10.658 0zM11.804 1.01C5.61 1.01.978 6.034.978 12.23s4.826 10.76 11.02 10.76S23.02 18.424 23.02 12.23c0-6.197-5.02-11.22-11.216-11.22zM12 21.355c-5.273 0-9.38-3.886-9.38-9.16 0-5.272 3.94-9.547 9.214-9.547a9.548 9.548 0 0 1 9.548 9.548c0 5.272-4.11 9.16-9.382 9.16zm3.108-9.75c.795 0 1.44-.88 1.44-1.963s-.645-1.96-1.44-1.96c-.795 0-1.44.878-1.44 1.96s.645 1.963 1.44 1.963z" fill="#7d8489"/></svg> */}
                      </div>
                      <input
            type="text"
            placeholder="Type your message ..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            className="input-msg"
            autocomplete="off" autofocus
        />
                    
                      {/* <div class="photo">
                        <i class="zmdi zmdi-camera"></i>
                      </div> */}
                      <button class="send">
                          <div class="circle">
                            <i class="mdi mdi-send"></i>
                          </div>
                        </button>
                    </form>
                  
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </React.Fragment>
    )
}