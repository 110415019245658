import React, { useState } from 'react';

const Countdown = ({ countDownTimerOpt }) => {
  const [getObj, setObj] = useState({
    formatTimer: null,
    countDownTimer: 0,
    intervalObj: null,
  });

  const { formatTimer, countDownTimer, intervalObj } = getObj;

  if (countDownTimerOpt > 0 && intervalObj === null) {
    startTimer();
  }

  function startTimer() {
    const x = setInterval(() => {
      timer();
    }, 1000);

    setObj((prev) => ({
      ...prev,
      countDownTimer: countDownTimerOpt,
      intervalObj: x,
    }));
  }

  function timer() {
    var days = Math.floor(countDownTimer / 24 / 60 / 60);
    var hoursLeft = Math.floor(countDownTimer - days * 86400);
    var hours = Math.floor(hoursLeft / 3600);
    var minutesLeft = Math.floor(hoursLeft - hours * 3600);
    var minutes = Math.floor(minutesLeft / 60);
    var remainingSeconds = countDownTimer % 60;

    const formatTimer1 =
      pad(days) +
      ':' +
      pad(hours) +
      ':' +
      pad(minutes) +
      ':' +
      pad(remainingSeconds);

    if (countDownTimer === 0) {
      clearInterval(intervalObj);
    } else {
      setObj((prev) => ({
        ...prev,
        formatTimer: formatTimer1,
        countDownTimer: prev['countDownTimer'] - 1,
      }));
    }
  }
  function pad(n) {
    return n < 10 ? '0' + n : n;
  }

  return <div>{formatTimer ? formatTimer : Math.random()}</div>;
};
export default Countdown;