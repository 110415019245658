import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Col, CardBody, Card, Progress, CardTitle } from "reactstrap"
// import { Link } from "react-router-dom"
import CircularProgress from '@mui/material/CircularProgress';
//Import Components
import LatestNews from "./latest-news"
import MyProgress from "./my-progress"
import Gallery from "./gallery"
import Marquee from "react-fast-marquee";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { decode } from "html-entities";
import welcome from "../../assets/images/welcome.jpg"
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #ffb200',
  boxShadow: 24,
  p: 4,
  borderRadius: "10px"
};
const Dashboard = () => {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [announcements, setAnnouncements] = useState([]);
  const [attendence, setAttendence] = useState(0);
  const [zoomAttend, setZoomAttend] = useState(0);
  const [quizResult, setQuizResult] = useState(0);
  const [assignment, setAssignment] = useState(0);
  const [project, setProject] = useState(0);
  const [gat, setGat] = useState(0);
  const [gallery, setGallery] = useState(null);
  const [batch, setBatch] = useState(null);
  const [alert, setAlert] = useState('');
  const [batches, setBatches] = useState([]);
  const [open, setOpen] = React.useState(false);
  // useEffect(() => {
  //   axios.defaults.headers.common[
  //     "Authorization"
  //   ] = `Bearer ${global.config.Token}`;

    

  // }, []);
 


  
  return (
    <React.Fragment>
      <div className="page-content">

        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">Dashboard</h4>

              <div className="page-title-right">
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">Welcome to PHSP Kamyab Hunarmand Naujawan | LMS</li>
                </ol>
              </div>

            </div>
          </div>
        </Row>

        <Row>
          {/* <Col lg={12} className="mt-3">
        <Link to={'whatsapp'}><h6 className="m-0 p-0 "><i className="mdi mdi-whatsapp text-success whatsapp"></i> Apply for WhatsAPP Group</h6></Link>
        </Col> */}
          <Col lg={12}>
            <Card className="bg-primary rounded text-light">
              <CardBody>
                <Marquee direction="left" speed={50}>
                  <CardTitle className="h1 welcome text-light">Welcome to PHSP Kamyab Hunarmand Naujawan | Learning Management System</CardTitle>
                </Marquee>

              </CardBody>
            </Card>
          </Col>
          {/* <Col lg={6}>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">My Progress <span className='float-end'>
                  <select className='bg-primary text-light p-1 rounded' onChange={e => getBatchData(e)} >
                    {
                      batches.length > 0 ? batches.map((batche, i) => (
                        <option
                          value={batche.id}
                          selected={batche.batch_id == batch ? true : false}
                        >B-{batche.batch_id}</option>
                      )) : ''
                    }

                  </select></span></CardTitle>
                {loading ? <div style={{ height: 205 }}> <CircularProgress className='center-loader' /> </div> :
                  <MyProgress attendence={attendence} zoomAttend={zoomAttend} quizzes = {quizResult} assigments = {assignment}  projects = {project} gat={gat} />
                }
              </CardBody>
            </Card>
          </Col>
          <Col lg={6}>
            {
              loading ? <Card className="h340 "><div style={{ height: 205 }}> <CircularProgress className='center-loader' /> </div> </Card> :
                <LatestNews news={news} announcements={announcements} />
            }


          </Col> */}

        </Row>
        <Row>
        {/* <Col lg={12}>
            <Card>
            <iframe src={"https://www.youtube.com/embed/lfpGxJc1se0?si=kWCkHNbuoslKTAak"} style={{ height:'500px',widows:"100%" }} title='ok' className='img-fluid' alt=''></iframe>
            </Card>
         
          </Col> */}
          <Col lg={12}>
          <img src={welcome} className='img-fluid' alt=''></img>
          </Col>

         
        </Row>
      </div>
     
    </React.Fragment>
  )
}

export default Dashboard