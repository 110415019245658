import React, { useState } from "react"

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap"
import { Link } from "react-router-dom"

//Lightbox
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import ModalVideo from "react-modal-video"
import "react-modal-video/scss/modal-video.scss"

// import image
// import img1 from "../../assets/images/small/welcome.jpg"
import img2 from "../../assets/images/small/img-2.jpg"
import img3 from "../../assets/images/small/img-3.jpg"
import img4 from "../../assets/images/small/img-4.jpg"
import img5 from "../../assets/images/small/img-5.jpg"
import img6 from "../../assets/images/small/img-6.jpg"
import img7 from "../../assets/images/small/img-7.jpg"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const images = [img2, img3, img4, img5, img6]

const UiLightbox = (props) => {
  const img1 = props.gallery
  const [photoIndex, setphotoIndex] = useState(1)
  const [isFits, setisFits] = useState(false)
  const [isEffects, setisEffects] = useState(false)
  const [isGallery, setisGallery] = useState(false)
  const [isGalleryZoom, setisGalleryZoom] = useState(false)


  return (
    <React.Fragment>
      
        {isFits ? (
          <Lightbox
            mainSrc={images[photoIndex]}
            enableZoom={false}
            imageCaption={
              "Caption. Can be aligned it to any side and contain any HTML."
            }
            onCloseRequest={() => {
              setisFits(!isFits)
             
            }}
          />
        ) : null}

        {isEffects ? (
          <Lightbox
            mainSrc={images[3]}
            enableZoom={false}
            onCloseRequest={() => {
              setisEffects(!isEffects)
            }}
          />
        ) : null}

        {isGallery ? (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            enableZoom={true}
            onCloseRequest={() => {
              setisGallery(false)
            }}
            onMovePrevRequest={() => {
              setphotoIndex((photoIndex + images.length - 1) % images.length)
            }}
            onMoveNextRequest={() => {
              setphotoIndex((photoIndex + 1) % images.length)
            }}
            imageCaption={"Project " + parseFloat(photoIndex + 1)}
          />
        ) : null}

        {isGalleryZoom ? (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => {
              setisGalleryZoom(false)
            }}
            onMovePrevRequest={() => {
              setphotoIndex((photoIndex + images.length - 1) % images.length)
            }}
            onMoveNextRequest={() => {
              setphotoIndex((photoIndex + 1) % images.length)
            }}
          />
        ) : null}

        <Row>
          <Col lg={12}>
            <Card>
              <CardBody>
                <CardTitle className="h4">Gallery</CardTitle>
               
                <Row>
                
                  <Col className="col-12">
                    
                    <div>
                      
                      <img
                       
                        className="img-fluid"
                        alt="Banner"
                        src={global.config.URL.img.public + img1}
                        width="100%"
                      />
                    </div>
                  </Col>
                  
                  <Col className="col-6 mt-3">
                  <Card >
                  <CardBody className="p-0">
                  <div className="ratio ratio-1x1">
                    <iframe
                      title="YouTube video"
                      allowFullScreen
                      src="https://www.youtube.com/embed/p7xQLYaQXKY"
                      
                    />
                  </div>
                </CardBody>
              </Card>
                  </Col>
                  <Col className="col-6 mt-3">
                  <Card >
                  <CardBody className="p-0">
                  <div className="ratio ratio-1x1">
                    <iframe
                      title="YouTube video"
                      allowFullScreen
                      src="https://www.youtube.com/embed/dh3Vc16V_80"
                      
                    />
                  </div>
                </CardBody>
              </Card>
                  </Col>
                 
                  
                </Row>
              </CardBody>
            </Card>
          </Col>

         
        </Row>

    </React.Fragment>
  )
}

export default UiLightbox
