import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { FaInfoCircle } from "react-icons/fa";
import { useState, useEffect, useRef } from "react";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import { BiInfoCircle } from 'react-icons/bi';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { Button, CardActionArea, CardActions } from '@mui/material';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb"
import { Col,CardBody, CardHeader, Row } from 'reactstrap';

import axios from 'axios';



export default function VerticalTabs(props) {
 
  const [courses, setCourses] = useState([]);
 

  
  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${global.config.Token}`;

    const getData = async () => {
      const courses = await axios.get(global.config.URL.api.lms + 'get-courses');
      // console.log(courses.data,'invoices')
       setCourses(courses.data.courses);
    }
    getData();

  },[]);

  const navigateToComunity = (student_course,course_id,batch_id) => {

    props.history.push({
      pathname: '/comunity-chat',
      state: {
        student_course: student_course,
        course_id: course_id,
        batch_id: batch_id,
      }
    })
  }
  


  return (
    <React.Fragment>
         <div className="page-content">
         <Breadcrumb title="Dashboard" breadcrumbItem={"PFTP Comunity"} />
         <Row>
                  {
                    courses.map((course, i) => (
                      <Col md={4} sm={6} className="mt-3">
                        <Card sx={{ maxWidth: 345 }} onClick={() => navigateToComunity(course.sid,course.id,course.batch_id)} >
                          <CardActionArea>
                            {/* <CardMedia
                              component="img"
                              height="140"
                              image={global.config.URL.img.public + course.course.course_detail.img}
                              alt="green iguana"
                            /> */}
                            <CardContent>
                              <Typography gutterBottom variant="p" className='heading' component="div">
                                {course.name}
                              </Typography>
                              
                            </CardContent>
                          </CardActionArea>
                          <CardActions>

                            <Button size="small" onClick={() => navigateToComunity(course.sid,course.id,course.batch_id)} className='btnn'>
                              <i className='mdi mdi-whatsapp'></i> &nbsp; Enter In Chat Room
                            </Button>

                          </CardActions>
                        </Card>
                      </Col>
                    ))

                  }
                </Row>
    </div>
     
    </React.Fragment>
  );
}