import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import courseImg from "../../assets/images/Digital-Marketing.jpg";
import webimg6 from "../../assets/images/webimg6.jpg";
import webimg2 from "../../assets/images/webimg2.jpg";
import { Link, useHistory } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import {
  Col,
  Row,
  CardText,
  CardBody,
  CardTitle,
} from "reactstrap";
import Marquee from "react-fast-marquee";
import "../../helpers/helper";



function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function BasicTabs(props) {
  const [error, setError] = useState(null);
  const [tid, setTid] = useState(null);
  const [address, setAddress] = useState(null);
  const [file, setFile] = useState();
  const [value, setValue] = React.useState(0);
  const [invoices, setInvoice] = useState(null);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${global.config.Token}`;

    const getData = async () => {
      let total = 0;
      const invoices = await axios.get(global.config.URL.api.lms + 'my-certificates');
      setInvoice(invoices.data.invoices);
      const numberOfCertificates = invoices.data.invoices[0].courses.length;
  
      // Adjust the pricing based on the number of certificates
      if (numberOfCertificates === 1) {
        total += 600;
      } else if (numberOfCertificates === 2) {
        total += 800;
      } else if (numberOfCertificates === 3) {
        total += 1000;
      } else if (numberOfCertificates === 4) {
        total += 1200;
      } else if (numberOfCertificates >= 5) {
        total += 1500;
      }
      setTotal(total);
    }
  
  
    
    getData();
    

  },[]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  calculateTotalAmount(newValue);
  };

  const navigateToModule = async (moduleId) => {

    const certificate = await axios.get(global.config.URL.api.lms + 'download-certificate/' + moduleId);
    const win = window.open(certificate.data.pdf, '_blank');
  if (win != null) {
    win.focus();
    }
  }

  function handleChangeApply(event) {
    setFile(event.target.files[0])
  }
  function handleChangeId(event) {
    setTid(event.target.value)
  }
  function handleChangeAddress(event) {
    setAddress(event.target.value)
  }
  function handleSubmit(event) {
    event.preventDefault()
    const url = global.config.URL.api.lms + 'request-certificate';
    const formData = new FormData();
    formData.append('img', file);
    formData.append('tr_id', tid);
    formData.append('address', address);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios.post(url, formData, config).then((response) => {
      if (response.data.success) {
        setError("Successfully Submitted")
        
      } else if (response.data.success === false) {
        setError(response.data.message)
      }
  
    });
  
  }
  const calculateTotalAmount = (activeTabIndex) => {
    let total = 0;
  
    // Check if invoices and courses are available
    if (invoices && invoices[activeTabIndex]) {
      const numberOfCertificates = invoices[activeTabIndex].courses.length;
  
      // Adjust the pricing based on the number of certificates
      if (numberOfCertificates === 1) {
        total += 600;
      } else if (numberOfCertificates === 2) {
        total += 800;
      } else if (numberOfCertificates === 3) {
        total += 1000;
      } else if (numberOfCertificates === 4) {
        total += 1200;
      } else if (numberOfCertificates >= 5) {
        total += 1500;
      }
    }
  
    setTotal(total);
  };
  
  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Certificate" breadcrumbItem="Select Course To Get Certificate" />
       
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              {
                invoices ? invoices.map((batch, i) => (
                  <Tab label={"Batch " + batch.batch.number} {...a11yProps(i)} />
                ))
                  :
                  ""
              }
            </Tabs>
          </Box>
          
        {

            invoices ? 
            invoices.length > 0 ?
            invoices.map((batch1, i) => (
              <TabPanel value={value} index={i}>
                <Row>
               
                  {
                    batch1.courses.map((course, i) => (
                      <Col md={4} sm={6} className="mt-3">
                        <Card sx={{ maxWidth: 345 }} >
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="140"
                              image={global.config.URL.img.public + course.course.course_detail.img}
                              alt="green iguana"
                            />
                            <CardContent>
                              <Typography gutterBottom variant="h6" className='heading' component="div">
                                {course.course.name}
                              </Typography>
                            
                            </CardContent>
                          </CardActionArea>
                          <CardActions>

                            <Button size="small" onClick={() => navigateToModule(course.id)} className='btnn'>
                              <i className='fa fa-arrow-down'></i> &nbsp; Download Certificate
                            </Button>

                          </CardActions>
                        </Card>
                      </Col>
                    ))

                  }
                   <Col md={12} sm={12} className="mt-3">
                <Card>
                <Row>
                <Col md={6} sm={6} className="mt-3">
               
                <CardActionArea className='p-3'>
                <h4 class="card-title">Dear PFTPian</h4>
                <p class="justify">If anyone required hard copy, then fill the form along with your complete TCS Address by paying <b>{total}rs</b>. (Delivery charges) </p>
                <ul>
                            <li><b>Bank Name:</b> Meezan Bank</li>
                            <li><b>Account Title:</b> PAKISTAN FREELANCING TRAINING PROGRAM</li>
                            <li><b>Account #:</b> 11420105463600 </li>
                            <li><b>IBAN:</b>  PK15MEZN0011420105463600 </li>
                        </ul>
                        <br/> <b>Thank you</b>
                </CardActionArea>
               
                </Col>
                <Col md={6} sm={6} className="mt-3">
               
                <Box>
            <Typography id="modal-modal-title" variant="h6" component="h2">
            
            </Typography>
            <form onSubmit={handleSubmit} className="m-3 form-froup">
               <label>Transection Id</label>
              <input type="text" placeholder='Enter Transection ID' className='form-control' onChange={handleChangeId} />
              <label>Transection Image</label>
              <input type="file" className='form-control' onChange={handleChangeApply} />
              <label class="form-label" >Postal Address:</label>
              <textarea className='form-control'  placeholder="Enter your postal address" onChange={handleChangeAddress} ></textarea>
              {error ? <p className='m-0 text-danger'>{error} </p> : ""}
              <Button type='submit' style={{color:"#fff"}} className="btn mt-3"
              >
                 Submit
              </Button>
            </form>
           
          </Box>
               
                </Col>
                </Row>
                </Card>
                  </Col>
                </Row>
              </TabPanel>
            ))
            : <Col md={12} >
            <Card body className='p-3'>

              <CardText>

                <h2>  Dear PFTPians,</h2> You will be able to access your certificate after the GAT examination which will be held at the end of your course.
              </CardText>

            </Card>
          </Col>  :
              <Row>
                <Col md={4} sm={6} className="mt-3">
                  <Stack spacing={1}>
                    <Skeleton animation="wave"  variant="rectangular" width={'100%'} height={140} />
                    <Skeleton width="60%" />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" height={20} sx={{ fontSize: '1rem' }} />

                  </Stack>
                </Col>
                <Col md={4} sm={6} className="mt-3">
                  <Stack spacing={1}>
                    <Skeleton animation="wave"  variant="rectangular" width={'100%'} height={140} />
                    <Skeleton width="60%" />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" height={20} sx={{ fontSize: '1rem' }} />

                  </Stack>
                </Col>
                <Col md={4} sm={6} className="mt-3">
                  <Stack spacing={1}>
                    <Skeleton animation="wave"  variant="rectangular" width={'100%'} height={140} />
                    <Skeleton width="60%" />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" height={20} sx={{ fontSize: '1rem' }} />

                  </Stack>
                </Col>
              </Row>
          } 
        </Box>
      </div>
    </React.Fragment>
  );
}