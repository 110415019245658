import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import logo from "../../assets/images/logo.png";
import { Link } from "react-router-dom"
import axios from 'axios';
import {
  Button,
  Col,
  Row,
} from "reactstrap"
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
export default function ActionAreaCard(props) {
  const { course } = props.location.state ? props.location.state : props.history.push('quiz-courses')
  const [quizzes, setQuizzes] = useState();
  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${global.config.Token}`;

    const getData = async () => {
      const Quizz = await axios.get(global.config.URL.api.lms + 'get-quizzes/' + course);
      setQuizzes(Quizz.data);
    }
    getData();

  },[]);
  const takeQuiz = (moduleId) => {
   
    props.history.push({
      pathname: '/quiz',
      state: {
        module: moduleId,
        StdC:course
      }
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem={"Quizzes"} />
        <Row>
          
          
        {
            quizzes ? 
            quizzes.weeks.map((module, i) => (
              <Col md={3} className="mt-3">
                <Card  >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      width="100%"
                      image={logo}
                      alt="green iguana"
                    />
                    <CardContent>
                      <Typography align="center" gutterBottom variant="h5" component="div">
                       Module {module.name} Quiz
                      </Typography>
                      {
                        module.quiz ?
                        <Button type={'button'} className='btn btn-primary btn-block w-100'>
                        You Got {module.quiz.marks}/{module.quiz.out_of}
                       </Button>
                        : 
                        <Button type={'button'} className='btn btn-warning btn-block w-100' onClick={() => takeQuiz(module.id)}>
                        Take
                       </Button>
                      }
                     
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Col>
            ))
             :
             <Row>
             <Col md={3} sm={6} className="mt-3">
               <Stack spacing={1}>
                 <Skeleton variant="rectangular" width={'100%'} height={140} />
                 <Skeleton width="60%" />
               </Stack>
             </Col>
             <Col md={3} sm={6} className="mt-3">
               <Stack spacing={1}>
                 <Skeleton variant="rectangular" width={'100%'} height={140} />
                 <Skeleton width="60%" />
               </Stack>
             </Col>
             <Col md={3} sm={6} className="mt-3">
               <Stack spacing={1}>
                 <Skeleton variant="rectangular" width={'100%'} height={140} />
                 <Skeleton width="60%" />
               </Stack>
             </Col>
             <Col md={3} sm={6} className="mt-3">
               <Stack spacing={1}>
                 <Skeleton variant="rectangular" width={'100%'} height={140} />
                 <Skeleton width="60%" />
               </Stack>
             </Col>
           </Row>
          }
          
        </Row>
      </div>
    </React.Fragment>

  );
}