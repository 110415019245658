import React, { useState, useEffect } from 'react';
import ReactApexChart from "react-apexcharts"
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';

class MyProgress extends React.Component {
  constructor(props) {
    super(props);
    const attendence = props.attendence;
    const zoomAttend = props.zoomAttend;
    const quizzes = props.quizzes;
    const assigments = props.assigments;
    const projects = props.projects;
    const gat = props.gat;
    this.state = {
          
      series: [{
        data: [zoomAttend,quizzes,assigments,projects,gat]
      }],
      options: {
        chart: {
          type: 'bar',
          height: 280
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom'
            },
          }
        },
        colors: ['#1a5b87', '#45cb85', '#eeb902', '#ff715b','#000'
        ],
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#fff']
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + '%'
          },
          offsetX: 0,
          dropShadow: {
            enabled: true
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          categories: ['Zoom Sessions', 'Quizzes', 'Assignments', 'Projects','GAT'
          ],
        },
        yaxis: {
          labels: {
            show: false
          }
        },
        title: {
            // text: 'Custom DataLabels',
            // align: 'center',
            // floating: true
        },
        subtitle: {
            // text: 'Category Names as DataLabels inside bars',
            // align: 'center',
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function () {
                return ''
              }
            }
          }
        }
      },
    
    
    };
  }



  render() {
    return (
      

<div id="chart">
<ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={244} />
</div>
    )
}
}
export default MyProgress
