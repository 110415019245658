import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { Button, CardActionArea, CardActions } from '@mui/material';
import Breadcrumbs from "../../components/Common/Breadcrumb"
import courseImg from "../../assets/images/Digital-Marketing.jpg";
import webimg6 from "../../assets/images/webimg6.jpg";
import webimg2 from "../../assets/images/webimg2.jpg";
import { Link, useHistory } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import {
  Col,
  Row,
  CardBody,
  CardTitle,
} from "reactstrap";
import Marquee from "react-fast-marquee";
import "../../helpers/helper";


function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


export default function BasicTabs(props) {

  const [value, setValue] = React.useState(0)
  const [invoices, setInvoice] = useState(null);
  useEffect(() => {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${global.config.Token}`;

    const getData = async () => {
      const invoices = await axios.get(global.config.URL.api.lms + 'my-courses');
      console.log(invoices,'invoices')
      setInvoice(invoices.data.invoices);
    }
    getData();

  },[]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigateToModule = (moduleId) => {

    props.history.push({
      pathname: '/modules',
      state: {
        course: moduleId,
      }
    })
  }
  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem="Select Course To Get Lectures" />
        <Row>
          <Col lg={12}>
            <Card className="">
              <CardBody>
                <Marquee direction="left" speed={50}>
                  <CardTitle className="welcome">Click on your course tab to get Pre-recorded lectures.</CardTitle>
                </Marquee>
              </CardBody>
            </Card>
          </Col>
          </Row>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              {
                invoices ? invoices.map((batch, i) => (
                  <Tab label={"Batch " + batch.batch.number} {...a11yProps(i)} />
                ))
                  :
                  ""
              }
            </Tabs>
          </Box>
          
        {

            invoices ? invoices.map((batch1, i) => (
              <TabPanel value={value} index={i}>
                <Row>
                  {
                    batch1.courses.map((course, i) => (
                      <Col md={4} sm={6} className="mt-3">
                        <Card sx={{ maxWidth: 345 }} onClick={() => navigateToModule(course.id)} >
                          <CardActionArea>
                            <CardMedia
                              component="img"
                              height="140"
                              image={global.config.URL.img.public + course.course.course_detail.img}
                              alt="green iguana"
                            />
                            <CardContent>
                              <Typography gutterBottom variant="h6" className='heading' component="div">
                                {course.course.name}
                              </Typography>
                              <Typography variant="body2" className='D
                              desc' align="justify" color="text.secondary">
                                {course.course.description}

                              </Typography>
                            </CardContent>
                          </CardActionArea>
                          <CardActions>

                            <Button size="small" onClick={() => navigateToModule(course.id)} className='btnn'>
                              <i className='fa fa-book'></i> &nbsp; Enter In Class
                            </Button>

                          </CardActions>
                        </Card>
                      </Col>
                    ))

                  }
                </Row>
              </TabPanel>
            ))
              :
              <Row>
                <Col md={4} sm={6} className="mt-3">
                  <Stack spacing={1}>
                    <Skeleton animation="wave"  variant="rectangular" width={'100%'} height={140} />
                    <Skeleton width="60%" />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" height={20} sx={{ fontSize: '1rem' }} />

                  </Stack>
                </Col>
                <Col md={4} sm={6} className="mt-3">
                  <Stack spacing={1}>
                    <Skeleton animation="wave"  variant="rectangular" width={'100%'} height={140} />
                    <Skeleton width="60%" />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" height={20} sx={{ fontSize: '1rem' }} />

                  </Stack>
                </Col>
                <Col md={4} sm={6} className="mt-3">
                  <Stack spacing={1}>
                    <Skeleton animation="wave"  variant="rectangular" width={'100%'} height={140} />
                    <Skeleton width="60%" />
                    <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                    <Skeleton variant="text" height={20} sx={{ fontSize: '1rem' }} />

                  </Stack>
                </Col>
              </Row>
          } 
        </Box>
      </div>
    </React.Fragment>
  );
}