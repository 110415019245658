import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  CardColumns,
  CardGroup,
  Badge,
  Button,
} from "reactstrap";
import { Input } from '@mui/material';
import moment from 'moment';

// import images
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Link } from "react-router-dom"
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ReactPlayer from 'react-player/youtube'
import CircularProgress from '@mui/material/CircularProgress';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #ffb200',
  boxShadow: 24,
  p: 4,
  borderRadius: "10px"
};
const stylevideo = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  height: 450,
  bgcolor: 'background.paper',
  border: '2px solid #ffb200',
  boxShadow: 24,
  p: 4,
  borderRadius: "10px"
};
const Assignment = props => {

  const [assignments, setAssignments] = useState();
  const [now, setNow] = useState();
  const [assignment, setAssignment] = useState(null);
  const [file, setFile] = useState()
  const [error, setError] = useState(null)
  const [course, setCourse] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openVideo, setOpenVideo] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  
  const handleOpenVideo = () => {
    setOpenVideo(true);
  }
  const handleCloseVideo = () => { 
    setOpenVideo(false);
  }
  const handleOpen = (id, course) => {
    setCourse(course);
    setAssignment(id);
    setOpen(true)
  };
  const handleClose = () => { setOpen(false); setError(false); setAssignment(null) }
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${global.config.Token}`;

  const getData = async () => {
    const ResAssignments = await axios.get(global.config.URL.api.lms + 'my-assignments');
    console.log(ResAssignments.data, 'ResAssignments.data')
    setAssignments(ResAssignments.data);
    setNow(ResAssignments.data.now)
  }
  useEffect(() => {

    getData();
  }, []);
  function handleChange(event) {
    setFile(event.target.files[0])
  }

  function handleSubmit(event) {
    event.preventDefault()
    const url = global.config.URL.api.lms + 'submit-assignment';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('id', assignment);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios.post(url, formData, config).then((response) => {
      if (response.data.success) {
        getData();
        setOpen(false);
      } else if (response.data.success == false) {
        setError(response.data.message)
      }

    });

  }

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem="Assignments" />


        <Row>
          {
            assignments ? <>
              {
                assignments.assignments.length > 0 ?
                  <>
                    {
                      assignments.assignments.map((assignment, i) => (
                        <Col md={12}>
                          <Card body>
                            <CardTitle className="mt-0">
                              <h6 className="float-end m-0"><Badge className="bg-primary">
                                Deadline: {moment(assignment.assignment.deadline).format("LL")}
                              </Badge></h6>
                              <h4 className="m-0"> {assignment.assignment.course ? assignment.assignment.course.name : ""} <sup>B-{assignment.assignment.batch ? assignment.assignment.batch.number : ""}</sup> { assignment.assignment.deadline >= now ? <a href={global.config.URL.img.public + assignment.assignment.file} target={'_blank'}><i class="mdi mdi-download text-primary blink_me" title="Download Assignment"></i></a> : null}</h4>

                            </CardTitle>
                            <CardText>
                              {assignment.assignment.text}
                              <Button className="bg-dark float-end mt-3 btn-sm" onClick={() => handleOpenVideo()} > <span className='blink_me'> How to Upload ?</span></Button>
                            </CardText>
                            <p>

                              {assignment.status === 0 && assignment.assignment.deadline >= now ? (
                                <Badge pill className="rounded-pill bg-warning">
                                  New
                                </Badge>
                              ) : assignment.status === 0 && assignment.assignment.deadline < now ? (
                                <Badge pill className="rounded-pill bg-danger">
                                  Missed
                                </Badge>
                              ) : assignment.status === 1 ? (
                                <Badge pill className="rounded-pill bg-success">
                                  Submitted
                                </Badge>
                              ) : null}
                              <p className='float-end'>

                                {assignment.status === 0 && assignment.assignment.deadline < now ? (
                                  <Badge pill className="rounded-pill bg-danger">
                                    Marks: <strong>{assignment.marks}  / {assignment.assignment.marks}</strong>
                                  </Badge>

                                ) : assignment.status === 1 && assignment.checked === 1 ? (
                                  <>
                                    <Badge pill className="rounded-pill bg-primary">
                                      Marks: <strong>{assignment.marks} / {assignment.assignment.marks}</strong>
                                    </Badge><br></br>
                                    <Badge pill className="rounded-pill bg-success">
                                      Comments: <strong>{assignment.comment}</strong>
                                    </Badge>

                                  </>
                                ) : assignment.status === 1 && assignment.checked === 0 ? (
                                  <Badge pill className="rounded-pill bg-warning">
                                    Checking in Progress ...
                                  </Badge>
                                ) : null}

                              </p>
                            </p>
                            {
                              assignment.assignment.deadline >= now ?
                              <Button onClick={() => handleOpen(assignment.id, assignment.assignment.course.name)} className="btn btn-primary bg-primary waves-effect btn-sm waves-light"
                            >
                              <i class="mdi mdi-upload text-light" ></i> {assignment.status == 1 ? "Update Assignment" : "Submit Assignment"} 
                            </Button>:""
                            }
                            
                          </Card>
                          <hr></hr>
                        </Col>

                      ))}
                  </>

                  :
                  <Col md={12}>
                    <Card body>

                      <CardText>

                        <h2> {assignments.length} Dear PFTPians,</h2> we are glad to see you here but there's no need to worry if you find us as an empty ground. You'll get your assignments once your module number 04 is unlocked. Keep in touch with us and don't forget to follow your course calender.
                      </CardText>

                    </Card>
                  </Col>
              }

            </> :
              <Col md={12} sm={12} className="mt-3">
                <Stack spacing={1}>
                  <Skeleton variant="rectangular" width={'100%'} height={100} />
                  <Skeleton width="60%" />
                  <Skeleton width="10%" />
                  <Skeleton width="100%" height={50} />
                </Stack>
              </Col>
          }


        </Row>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Upload {course} Assignment
            </Typography>
            <form onSubmit={handleSubmit} className="m-3 form-froup">

              <input type="file" className='form-control' onChange={handleChange} />
              {error ? <p className='m-0 text-danger'>{error} </p> : ""}
              <Button type='submit' className="btn mt-3 btn-primary bg-primary waves-effect btn-sm waves-light"
              >
                <i class="mdi mdi-upload text-light" ></i>  Upload Assignment
              </Button>
            </form>
            <Typography id="modal-modal-description" className='text-primary' sx={{ mt: 2 }}>
              File should be in pdf,docx,doc,xlsx,ppt,pptx format
            </Typography>
          </Box>
        </Modal>

        <Modal
          open={openVideo}
          onClose={handleCloseVideo}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={stylevideo}>

          {loading ? <div> <CircularProgress className='video-loader' /> </div> : ''}
                      <ReactPlayer url={"https://www.youtube.com/embed/n-o-fuMUnjg"}
                        playing={false}
                        controls={true}
                        width='100%'
                        height='100%'
                        responsive={true}
                        onReady={() =>  setLoading(false)}
                        onEnded={() => setOpenVideo(false)}
                        fileConfig={{ attributes: { preload: 'none' } }}
                      />
          </Box>
        </Modal>
      </div>
    </React.Fragment>
  )
}
export default Assignment
