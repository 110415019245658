import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImg,
  CardText,
  CardHeader,
  CardImgOverlay,
  CardFooter,
  CardDeck,
  CardColumns,
  CardGroup,
  Badge,
  Button,
} from "reactstrap";
import { Input } from '@mui/material';
import moment from 'moment';

// import images
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { Link } from "react-router-dom"
import axios from 'axios';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ReactPlayer from 'react-player/youtube'
import CircularProgress from '@mui/material/CircularProgress';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"


const HowToUse = props => {

 

  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem="How To Use LMS" />


        <Row>
         
        <Col className="col-12 mt-3">
                  <Card >
                  <CardBody className="p-0">
                  <div className="">
                    <iframe
                      height={720}
                      width={'100%'}
                      title="YouTube video"
                      allowFullScreen
                      src="https://www.youtube.com/embed/ie4ZiFTZNTs?si=9nR07ZeMuX8C0hTO?rel=0?listType=playlist"
                      
                    />
                  </div>
                </CardBody>
              </Card>
                  </Col>

      </Row>
      </div>
    </React.Fragment>
  )
}
export default HowToUse
