import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Card,
  
  CardText,
 
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../components/Common/Breadcrumb"

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  bgcolor: 'background.paper',
  border: '2px solid #ffb200',
  boxShadow: 24,
  p: 4,
  borderRadius: "10px"
};
const stylevideo = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 850,
  height: 450,
  bgcolor: 'background.paper',
  border: '2px solid #ffb200',
  boxShadow: 24,
  p: 4,
  borderRadius: "10px"
};
const Text = props => {



  return (
    <React.Fragment>
      <div className="page-content">

        <Breadcrumbs title="Dashboard" breadcrumbItem="Temporary Locked" />


        <Row>
         
                  <Col md={12}>
                    <Card body>

                      <CardText>
{props.match.params.type === "Lecture" ? 


<><h2> Dear Students,</h2> Your Lectures will be updated after the completion of your Grand Scholarship Test</>
:
props.match.params.type === "Certificate" ? 
<><h2> Dear Students,</h2> You will be able to the download the certificate after the completion of your course</>
:
<> <h2> Dear Students,</h2> Your Grand Scholarship Test date will be announced by the end of this month.
                        <p>For syllabus preparation and FAQ kindly check the FAQ Tab Best Of Luck Stay Tuned.</p>
</>
}
                     
                      
                       
                       
                      
                      </CardText>

                    </Card>
                  </Col>
         


        </Row>
       
      </div>
    </React.Fragment>
  )
}
export default Text
