import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Col,
  Row,

} from "reactstrap"

import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import { Link } from "react-router-dom"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"


function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Announcement = (props) => {
  const [value, setValue] = React.useState(0)
  const [news, setNews] = useState([]);
  // useEffect(() => {
  //   axios.defaults.headers.common[
  //     "Authorization"
  //   ] = `Bearer ${global.config.Token}`;

  //   const getData = async () => {
  //     const Rnews = await axios.get(global.config.URL.api.lms + 'announcement');
  //     if(Rnews.data.success)
  //     {
  //       setNews(Rnews.data.news)
  //     }
      
  //   }
  //   getData();

  // },[]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="Latest News" />
       
        <Row>
        <Col lg={12}>
            <Card>
            <iframe src={"https://www.youtube.com/embed/lfpGxJc1se0?si=kWCkHNbuoslKTAak"} style={{ height:'500px',width:"100%" }} title='ok' className='img-fluid' alt=''></iframe>
            </Card>
         
          </Col>
          </Row>
        <Box sx={{ width: '100%' }}>
          {/* <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              {
               news.map((batch, i) => (
                  <Tab label={"Batch " + batch.batch.number} {...a11yProps(i)} />
                ))
                  
              }
            </Tabs>
          </Box> */}
        {
            news.map((batch1, i) => (
              <TabPanel value={value} index={i}>
  <Row className="g-0 align-items-center">
          {batch1.news.map((newa, i) => (
            <Col md={12} className="mt-3">
            <Card sx={{ display: 'flex' }}>
              <Row>
              <Col md={4}>
              <CardMedia
                  component={newa.is_video ? 'iframe' : 'img'}
                  sx={{ width: '100%', height: 200 }}
                  src={newa.is_video ? newa.asset : global.config.URL.img.public + newa.asset}
                  alt="Img"
                  allowfullscreen="true"
                />
              </Col>
              <Col md={8}>
                  <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                      {newa.title}
                    </Typography>
                    <Typography variant="subtitle1" align="justify" color="text.secondary" component="div">
                    {newa.description}
                    </Typography>
                  </CardContent>
                </Col>
              </Row> 
                </Card>
            </Col>
          ))
              
          }
      
        </Row>
              </TabPanel>

            )) 
          }
       </Box>

      
       
      </div>
    </React.Fragment>
  )
}

export default Announcement
