import PropTypes from 'prop-types'
import React, { useEffect, useState } from "react"
import { Row, Col, Alert, Container } from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"

// action
import { userForgetPassword } from "../../store/actions"
import axios from 'axios';
// import images
import logo from "../../assets/images/logo-sm-dark.png"
import { act } from '@testing-library/react'

const ForgetPasswordPage = props => {
  const [otp, setOtp] = useState(false);
  const [email, setEmail] = useState(null);
  const [mob, setMob] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  async function handleValidSubmit(event, values) {
    setLoading(true)
   
    const res = await axios.post(global.config.URL.api.user + 'forgot-password', {
      email: values.email,
      mob: values.mob
    });

    if (res.data.success) {
      setLoading(false)
      setError(null)
      setEmail(values.email)
      setSuccess(res.data.message)
    } else {
      setSuccess(null)
      setError(res.data.message)
      setLoading(false)

    }
  }
  async function verifyOTP(event, values) {

    const res = await axios.post(global.config.URL.api.user + 'verify-otp', {
      email: email,
      otp: values.otp
    });

    if (res.data.success) {
      setError(null)
      setSuccess(res.data.message)
    } else {
      setSuccess(null)
      setError(res.data.message)

    }
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>


          <Row className="justify-content-center">
            <Col md={8} lg={6}>
              <div className="card overflow-hidden">
                <div className="bg-login text-center">
                  <div className="bg-login-overlay"></div>
                  <div className="position-relative">
                    <h5 className="text-white font-size-20">Reset Password</h5>
                    <p className="text-white-50 mb-0">Re-Password with PFTP.</p>

                    <a href="/" className="logo logo-admin mt-4">
                      <img src={logo} alt="" height="53" />
                    </a>
                  </div>
                </div>
                <div className="card-body pt-5">

                  <div className="p-2">
                    {error ? (
                      <Alert color="danger" className="text-center mb-4" style={{ marginTop: "13px" }}>
                        {error}
                      </Alert>
                    ) : null}
                    {success ? (
                      <Alert color="success" className="text-center mb-4" style={{ marginTop: "13px" }}>
                        {success}
                      </Alert>
                    ) : null}
                    {
                      otp ?
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => verifyOTP(e, v)}
                        >
                          <div className="mb-3">
                            <AvField
                              name="otp"
                              label="Code"
                              className="form-control"
                              placeholder="Enter Code"
                              type="number"
                              required
                              
                            />
                          </div>
                          <Row className="row mb-0">
                            <Col className="col-12 text-end">
                              <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="submit"
                              >
                                Verify
                              </button>
                            </Col>
                          </Row>
                        </AvForm>
                        :
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => handleValidSubmit(e, v)}
                        >
                          <div className="mb-3">
                            <AvField
                              name="email"
                              label="Email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="mob"
                              label="Mobile"
                              className="form-control"
                              placeholder="Enter Registered Mobile Number"
                              type="text"
                              required
                            />
                          </div>
                          <Row className="row mb-0">
                            <Col className="col-12 text-end">
                            
                              {
                                loading ?
                                <button
                                className="btn btn-primary w-md waves-effect waves-light"
                                type="button"
                                disabled={true}
                              >
                                sending..
                              </button>
                                :
                                <button
                                  className="btn btn-primary w-md waves-effect waves-light"
                                  type="submit"
                                >
                                  Reset
                                </button>
                                

                              }
                            </Col>
                          </Row>
                        </AvForm>
                    }
                  </div>
                </div>
              </div>
              <div className="mt-5 text-center">
                <p>Remember It ? <Link to="/login" className="fw-medium text-primary"> Sign In
                  here</Link> </p>
                <p>
                  © {new Date().getFullYear()}  Design & Develop by PFTP{" "}

                </p>
              </div>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  forgetError: PropTypes.any,
  forgetSuccessMsg: PropTypes.any,
  history: PropTypes.object,
  userForgetPassword: PropTypes.func
}

const mapStatetoProps = state => {
  const { forgetError, forgetSuccessMsg } = state.ForgetPassword
  return { forgetError, forgetSuccessMsg }
}

export default withRouter(
  connect(mapStatetoProps, { userForgetPassword })(ForgetPasswordPage)
)
